/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import styles from "./slider.module.scss";
import { URI } from "lib/config";
import Link from "next/link";
import useIsMobile from "hooks/isMobile";
import ImgixImage from "components/ImgixImage";
import { useCraftUri } from "lib/craftUri";

export default function FourthSlider({ slideData }: any): JSX.Element {
  const prepCraftUri = useCraftUri();
  const isMobile = useIsMobile();

  const data = slideData;
  const image = (isMobile && data?.mobileImage?.[0]) || data?.image?.[0];

  return (
    <div className={styles["fourth-slider"]}>
      {image && (
        <ImgixImage
          className={styles["fourth-slider__img"]}
          src={image.url}
          alt={image.title}
          width={image.width}
          height={image.height}
          priority
        />
      )}

      <div className={styles["fourth-slider__wrap"]}>
        <div className={styles["fourth-slider__info"] + " container"}>
          <div className={styles["fourth-slider__title"]}>
            <h2>{slideData?.header}</h2>
          </div>
          {data?.buttonText &&
          (data?.buttonLink?.[0]?.uri || data?.buttonExternalLink) ? (
            <Link
              href={
                data?.buttonLink?.[0]?.uri
                  ? prepCraftUri(data?.buttonLink?.[0]?.uri)
                  : data?.buttonExternalLink
              }
            >
              <a className="black-button">
                <span>{data?.buttonText}</span>
              </a>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
