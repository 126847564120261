import React from 'react-dom'
import { useState } from 'react'
import styles from './slider.module.scss'
import Slider from 'react-slick'
import ImgixImage from 'components/ImgixImage'
import { useStore } from 'hooks/locationContext'
import { Carousel_MatrixField } from 'lib/generated/graphql-types'

export type HouseSliderProps = {
  carousel: Carousel_MatrixField[]
}

type NextArrowType = {
  NextclassName: { first: string; second: string }
  style?: string
  onClick?: () => void
}

type PrevArrowType = {
  PrevClassName: { first: string; second: string }
  style?: string
  onClick?: () => void
}

function NextArrow(props: NextArrowType) {
  const { NextclassName, style, onClick } = props
  return (
    <div className={NextclassName.first} onClick={onClick}>
      <div className={NextclassName.second} />
    </div>
  )
}

function PrevArrow(props: PrevArrowType) {
  const { PrevClassName, style, onClick } = props
  return (
    <div className={PrevClassName.first} onClick={onClick}>
      <div className={PrevClassName.second} />
    </div>
  )
}

export default function HouseSlider({ carousel }: HouseSliderProps): JSX.Element {
  const currentLocation = useStore()[0]
  const { region, location } = currentLocation.initialLocation

  const [countSlides, setCountSlides] = useState(0)
  const [activeSlide, setActiveSlide] = useState(1)

  let slidesUI: any = []

  if (carousel) {
    slidesUI = carousel?.map((slide, index) => (
      <div className={styles['house-slider']} key={index as any}>
        <ImgixImage
          className={styles['house-slider__img']}
          src={slide?.image?.[0]?.url}
          alt={slide?.image?.[0]?.title}
          width={slide?.image?.[0]?.width}
          height={slide?.image?.[0]?.height}
          priority
        />
        <div className={styles['house-slider__bottom']}>{slide?.text}</div>
      </div>
    ))
  }

  return (
    <section className={styles['house-slider__wrap']}>
      <div className={styles['house-slider__top']}>
        {activeSlide} / {countSlides}
      </div>
      <Slider
        {...{
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          easing: 'ease',
          nextArrow: (
            <NextArrow
              NextclassName={{
                first: 'slider-button-wrap --yellow',
                second: 'slider-button-wrap__right',
              }}
            />
          ),
          prevArrow: (
            <PrevArrow
              PrevClassName={{
                first: 'slider-button-wrap --yellow',
                second: 'slider-button-wrap__left',
              }}
            />
          ),
          beforeChange: (current, next) => setActiveSlide(next + 1),
          onInit() {
            setCountSlides((this as any).children.length)
          },
        }}
      >
        {slidesUI}
      </Slider>
    </section>
  )
}
