/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import styles from "./slider.module.scss";
import Link from "next/link";
import useIsMobile from "hooks/isMobile";
import ImgixImage from "components/ImgixImage";
import { useCraftUri } from "lib/craftUri";

export default function FifthSlider({ slideData = {} }: any): JSX.Element {
  const prepCraftUri = useCraftUri();
  const isMobile = useIsMobile();

  const data = slideData;
  const image = (isMobile && data?.mobileImage?.[0]) || data?.image?.[0];

  return (
    <div className={styles["fifth-slider"]}>
      {image && (
        <ImgixImage
          className={styles["fifth-slider__img"]}
          src={image.url}
          alt={image.title}
          width={image.width}
          height={image.height}
          priority
        />
      )}

      <div className={styles["fifth-slider__wrap"]}>
        <div className={styles["fifth-slider__info"] + " container"}>
          <div className={styles["fifth-slider__info-wrap"]}>
            <h2 className={styles["fifth-slider__title"]}>
              {slideData?.header}
            </h2>
            <h3 className={styles["fifth-slider__text"]}>
              {slideData?.subheader}
            </h3>
            <div className={styles["fifth-slider__price"]}>
              {slideData?.price}
            </div>
            <ul>
              {slideData?.includes?.map((include: any) => (
                <li key={include?.text?.split(" ").join("-")}>
                  {include?.text}
                </li>
              ))}
            </ul>
            {data?.buttonText &&
            (data?.buttonLink?.[0]?.uri || data?.buttonExternalLink) ? (
              <Link
                href={
                  data?.buttonLink?.[0]?.uri
                    ? prepCraftUri(data?.buttonLink?.[0]?.uri)
                    : data?.buttonExternalLink
                }
              >
                <a className="black-button">
                  <span>{data?.buttonText}</span>
                </a>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
