import React, { useEffect, useState, useMemo } from 'react'
import styles from './easy.module.scss'
import { useStore } from 'hooks/locationContext'
import { HeaderAndText_MatrixField, RegionHeaderAndText_MatrixField } from 'lib/generated/graphql-types'
import Router, { useRouter } from 'next/router'

export type MadeEasyProps = {
  headerAndText: HeaderAndText_MatrixField[]
  regionHeaderAndText?: RegionHeaderAndText_MatrixField[]
}

const MadeEasy = ({ headerAndText, regionHeaderAndText }: MadeEasyProps): JSX.Element => {
  const router = useRouter()
  const location = router?.query?.location

  const filteredData = regionHeaderAndText?.find((item: any) => item?.locations?.some((loc: any) => loc?.slug === location))

  return (
    <section className={styles['easy-block']}>
      <div className="container">
        <div className={styles['easy-block__wrap']}>
          <div
            className={styles['easy-block__text']}
            dangerouslySetInnerHTML={{
              __html: filteredData?.text ? filteredData?.text : headerAndText?.[0]?.text,
            }}
          ></div>
          <h2
            className="yellow-span-top"
            dangerouslySetInnerHTML={{
              __html: filteredData?.header ? filteredData?.header : headerAndText?.[0]?.header,
            }}
          ></h2>
        </div>
      </div>
    </section>
  )
}

export default MadeEasy
