import React from "react-dom";
import styles from "./build-your-home.module.scss";
import Link from "next/link";
import { useRouter } from "next/router";
import { useStore } from "hooks/locationContext";
import Home from "images/svg/build-home.svg";
import Style from "images/svg/build-style.svg";
import Docs from "images/svg/build-document.svg";
import House from "images/svg/build-house.svg";
import getUri from "lib/getUri";

export default function BuildYourHome(): JSX.Element {
  const store = useStore()[0];
  const router = useRouter();

  return (
    <section className={styles["build"]}>
      <div className="container">
        <div className={styles["build__wrap"]}>
          <div className={styles["build__left"]}>
            <h3>Build your home in 3 easy steps</h3>
            <Link href={`${getUri(store.initialLocation)}/home-designs`}>
              <a className="black-button">
                <span>Explore our Home Designs</span>
              </a>
            </Link>
          </div>
          <div className={styles["build__right"]}>
            <ul>
              <li>
                <span>1</span>
                <div className={styles["build__right-wrap"]}>
                  <div className={styles["build__right-title"]}>
                    Start by choosing your Home Design
                  </div>
                  <div className={styles["build__right-text"]}>
                    Choose from our range of modern Australian home designs,
                    tailored to suit your needs.
                  </div>
                </div>
                <div className={styles["build__right-icon"]}>
                  <Home />
                </div>
              </li>
              <li>
                <span>2</span>
                <div className={styles["build__right-wrap"]}>
                  <div className={styles["build__right-title"]}>
                    Pick your styling and options
                  </div>
                  <div className={styles["build__right-text"]}>
                    Customise the look and feel of your new home with internal
                    and external colours and home design options.
                  </div>
                </div>
                <div className={styles["build__right-icon"]}>
                  <Style />
                </div>
              </li>
              <li>
                <span>3</span>
                <div className={styles["build__right-wrap"]}>
                  <div className={styles["build__right-title"]}>
                    We prepare Contracts and Documentation
                  </div>
                  <div className={styles["build__right-text"]}>
                    Let us help you take care of the documentation involved so
                    you have more time for what matters.
                  </div>
                </div>
                <div className={styles["build__right-icon"]}>
                  <Docs />
                </div>
              </li>
              <li>
                <div className={styles["build__right-wrap"]}>
                  <div className={styles["build__right-title--last"]}>
                    Start building your new Home
                  </div>
                </div>
                <div className={styles["build__right-icon"]}>
                  <House />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
