import React from 'react-dom'
import FirstSlider from './slides/first-slider'
import SecondSlider from './slides/second-slider'
import ThirdSlider from './slides/third-slider'
import Slider from 'react-slick'
import FourthSlider from './slides/fourth-slider'
import FifthSlider from './slides/fifth-slider'
import { useStore } from 'hooks/locationContext'
import { Banner_MatrixField } from 'lib/generated/graphql-types'
import { useEffect, useState } from 'react'

export type MainPageTopSliderProps = {
  banner: Banner_MatrixField[]
}

type NextArrowType = {
  NextclassName: { first: string; second: string }
  style?: string
  onClick?: () => void
}

type PrevArrowType = {
  PrevClassName: { first: string; second: string }
  style?: string
  onClick?: () => void
}

function NextArrow(props: NextArrowType) {
  const { NextclassName, style, onClick } = props
  return (
    <div className={NextclassName.first} onClick={onClick}>
      <div className={NextclassName.second} />
    </div>
  )
}

function PrevArrow(props: PrevArrowType) {
  const { PrevClassName, style, onClick } = props
  return (
    <div className={PrevClassName.first} onClick={onClick}>
      <div className={PrevClassName.second} />
    </div>
  )
}

const MainPageTopSlider = ({ banner }: MainPageTopSliderProps): JSX.Element => {
  const currentLocation = useStore()[0]
  const { region, location } = currentLocation.initialLocation
  const [slidesByLocations, setSlidesByLocations] = useState([])

  const slidesByLocation = (
    banner?.filter((slide) => {
      let result = !slide?.locations?.length ? true : (false as boolean)
      result = slide?.locations?.some((el: any) => el.slug === location || el.slug === region)
      return result
    }) || []
  ).map((slide) => {
    const buttonLinks = slide?.buttonLink || []
    const buttonLink = buttonLinks?.length ? { ...buttonLinks[0] } : null
    if (buttonLink?.uri?.startsWith('vic/melbourne')) {
      if (location) {
        buttonLink.uri = buttonLink.uri.replace('vic/melbourne/', `vic/${location}/`)
      } else {
        buttonLink.uri = buttonLink.uri.replace('vic/melbourne/', 'vic/')
      }
    }

    return { ...slide, buttonLink: [buttonLink] }
  })
  useEffect(() => {
    setSlidesByLocations(slidesByLocation)
  }, [currentLocation, banner])
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <NextArrow
        NextclassName={{
          first: 'slider-button-wrap --black --d-none-mob',
          second: 'slider-button-wrap__right',
        }}
        onClick={() => null}
        style=""
      />
    ),
    prevArrow: (
      <PrevArrow
        PrevClassName={{
          first: 'slider-button-wrap --black --d-none-mob',
          second: 'slider-button-wrap__left',
        }}
        onClick={() => null}
        style=""
      />
    ),
  }

  return (
    <section>
    <Slider {...settings}>
      {slidesByLocations?.map((slide: any, index: any) => {
        switch (slide?.typeHandle) {
          case 'firstType':
            return <FirstSlider key={slide.uid || index} slideData={slide} />

          case 'secondType':
            return <SecondSlider key={slide.uid || index} slideData={slide} />

          case 'thirdType':
            return <ThirdSlider key={slide.uid || index} slideData={slide} />

          case 'fourthType':
            return <FourthSlider key={slide.uid || index} slideData={slide} />

          case 'fifthType':
            return <FifthSlider key={slide.uid || index} slideData={slide} />
        }
      })}
    </Slider>
    </section>
  )
}

export default MainPageTopSlider
